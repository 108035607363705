var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        placement: "bottom",
        title: _vm.$t("lbl_invoice"),
        height: "576px",
        visible: _vm.visible
      },
      on: { close: _vm.emits }
    },
    [
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            },
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            current: _vm.pagination.page,
            pageSize: _vm.pagination.limit
          },
          scroll: { y: 700, x: 1000 },
          size: "small",
          "key-row": "id"
        },
        on: { change: _vm.onTableChange },
        scopedSlots: _vm._u([
          {
            key: "currency",
            fn: function(text) {
              return [_c("span", [_vm._v(_vm._s(_vm._f("currency")(text)))])]
            }
          },
          {
            key: "invoice",
            fn: function(text, record) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: _vm.getRoute(record.source),
                        params: { id: record.id }
                      }
                    }
                  },
                  [
                    _c(
                      "a-button",
                      { staticClass: "p-0", attrs: { type: "link" } },
                      [_vm._v(" " + _vm._s(text || "-") + " ")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }