

































import { useArAgingReport } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  ReportAgingDashboardDetailDto,
  ReportAgingDashboardHeadDto,
} from "@/models/interface/ar-aging-report";
import { Component, Vue } from "vue-property-decorator";
import DrawerInvoice from "./DrawerInvoice.vue";

type ReportPeriod = keyof ReportAgingDashboardHeadDto;

@Component({
  components: {
    DrawerInvoice,
  },
})
export default class ArAgingReminder extends Vue {
  drawer = {
    visible: false,
    data: [] as Array<ReportAgingDashboardDetailDto>,
    toggle(): void {
      this.visible = !this.visible;
    },
  };
  loading = false;
  dataReport: ReportAgingDashboardHeadDto =
    useArAgingReport().initReportAgingDashboardHeadDto();

  created(): void {
    this.fetchReport();
  }

  fetchReport(params?: RequestQueryParams): void {
    const { findAllDashboard } = useArAgingReport();
    this.loading = true;
    findAllDashboard(params)
      .then((res: ReportAgingDashboardHeadDto) => {
        this.dataReport = res;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  toggleDrawer(invoices: Array<ReportAgingDashboardDetailDto>): void {
    this.drawer.data = invoices;
    this.drawer.toggle();
  }

  onDrawerClose(): void {
    const empty = [];
    this.toggleDrawer(empty);
  }

  getTitle(key: ReportPeriod): string {
    if (key === "outstanding1") return this.$t("lbl_1_to_15_days").toString();
    else if (key === "outstanding2")
      return this.$t("lbl_16_to_30_days").toString();
    else if (key === "outstanding3")
      return this.$t("lbl_31_to_60_days").toString();
    else if (key === "outstanding4")
      return this.$t("lbl_more_than_60").toString();
    else return "";
  }
}
