










































import { trimSpaceToUnderscore } from "@/helpers/common";
import { APagination, Row } from "@/hooks";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { ReportAgingDashboardDetailDto } from "@/models/interface/ar-aging-report";
import { Component, Prop, Vue } from "vue-property-decorator";

type DataSource = Array<Row<ReportAgingDashboardDetailDto, string>>;

@Component
export default class DrawerInvoice extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Prop({ required: true, type: Boolean, default: false })
  visible!: boolean;

  @Prop({ required: false, type: Array })
  invoices!: DataSource;

  columns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: "75px",
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      scopedSlots: { customRender: "invoice" },
    },
    {
      title: this.$t("lbl_invoice_source"),
      dataIndex: "source",
    },
    {
      title: this.$t("lbl_outstanding"),
      dataIndex: "amount",
      scopedSlots: { customRender: "currency" },
    },
  ];

  pagination = {
    page: FIRST_PAGE,
    limit: DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = FIRST_PAGE;
    },
  };

  get dataSource(): DataSource {
    return this.invoices.map((item, i) => ({
      ...item,
      key: item.id,
      no: i + ONE,
    }));
  }

  emits(): void {
    this.pagination.reset();
    this.$emit("close");
  }

  getRoute(source: string): string {
    const RECURRING_INVOICE = "RECURRING_INVOICE";
    if (trimSpaceToUnderscore(source) === RECURRING_INVOICE) {
      return "accountreceivables.invoices.detail.recurring";
    } else {
      return "accountreceivables.invoices.detail";
    }
  }

  onTableChange(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page = current;

    if (this.pagination.limit !== pageSize) {
      this.pagination.page = FIRST_PAGE;
    }

    this.pagination.limit = pageSize;
  }
}
