var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [_c("a-col", { attrs: { span: 24 } }, [_c("tax-invoice-sn-alert")], 1)],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _vm.$can("read", "internal-contract")
            ? _c("a-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {
                    style: {
                      "overflow-y": "hidden",
                      width: "100%",
                      height: "100%"
                    }
                  },
                  [_c("reminder-contract")],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.$can("read", "sales-tracking")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [_c("SalesOrderTracking")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$can("read", "report-aging")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [_c("a-col", { attrs: { span: 24 } }, [_c("ArAgingReminder")], 1)],
            1
          )
        : _vm._e(),
      _vm.$can("read", "invoice-ar")
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [_c("a-col", { attrs: { span: 24 } }, [_c("invoice-overdue")], 1)],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }